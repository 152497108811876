import $ from 'jquery'
import {Currencies} from 'currencies-map'

// we will cache products objects here
var loadedGQLProducts = {
  "sa": {},
  "pss": {},
  "bundle": {},
  "psf": {},
  "cp": {}
};

export function loadProducts() {
  var
    url_string = document.location.href,
    url = new URL(url_string),
    get_ip = url.searchParams.get('px_test_ip_address');

  var ip = (!get_ip || get_ip === 'null' || get_ip === 'undefined' || get_ip.length === 0) ? '' : get_ip;
  var if_ip = (ip !== '') ? '(ip: "' + ip + '")' : '';

  // Get Data
  $.ajax({
    url: window.px_config.pxApi.gql.url,
    data: JSON.stringify({
      query: `
        query GetBillingRecurringProductsForCustomerIp {
          billingRecurringProductsForCustomerIp${if_ip} {
            id
            currencyCode
            period
            periodUnit
            price
            billingProductFamily {
              id
            }
            product {
              id
              name
              metaData
            }
          }
        }`
    }),
    method: "POST",
    dataType: 'json',
    contentType: 'application/json',
    headers: {
      'apollographql-client-name': window.px_config.pxApi.client.id,
      'apollographql-client-version': window.px_config.pxApi.client.version
    },
    success: function (response) {

      if (response) {

        // Formatting Data
        var data = response.data.billingRecurringProductsForCustomerIp;

        const periods = {
          DAY3: 'Monthly',
          DAY4: 'Yearly',
          DAY5: 'Half-Yearly',
          MONTH1: 'Monthly',
          YEAR1: 'Yearly',
          MONTH6: 'Half-Yearly',
        };

        for (var i in data) {

          const product_id = data[i].id;
          const product = data.find(product => product.id === product_id);
          const experimental = data[i].product.metaData && data[i].product.metaData.hasOwnProperty('experimental') && data[i].product.metaData.experimental === '1';

          const period = periods[`${product.periodUnit}${product.period}`];

          const product_family = product.billingProductFamily.id.toLowerCase();
          const product_name = product.product.name;

          if (!experimental) {
            let item = {
              "name": `${product.product.name} ${period}`,
              "currencyCode": product.currencyCode,
              "price": product.price / 100,
              "priceId": product_id,
              "slug": `${product.product.id}-${period}`,
              "period": period,
              "period_number": product.period,
              "product-family": product_family,
              "product-name": product_name
            };

            // Replace data if there are bonus plan
            if (
              window.pxCustomFitPricingExperiment &&
              window.pxCustomFitPricingExperiment[product_family.toUpperCase()]
            ) {
              let replacePlans = window.pxCustomFitPricingExperiment[product_family.toUpperCase()].replacePlans.find(replacePlans => replacePlans.from === product_id);

              if (
                replacePlans &&
                replacePlans.to
              ) {
                let replaceProduct = data.find(product => product.id === replacePlans.to);
                if (replaceProduct) {
                  item.priceId = replaceProduct.id;
                  item.price = replaceProduct.price / 100;
                  loadedGQLProducts[product_family][replaceProduct.id] = item;
                }
              }
            }

            loadedGQLProducts[product_family][product_id] = item;
          }

        }

        // SA Monthly prices
        const SAPlansCount = Object.keys(loadedGQLProducts.sa).length;
        const isSAPricingPage = $('#px-layout').hasClass('sa-pricing');

        if (isSAPricingPage && SAPlansCount > 2) {
          $('.pricing-header').addClass('-with-monthly');
        }

        setProductsData(loadedGQLProducts);

        let prices = [];
        i = 0;
        $('[data-type="price"]').each(function () {
          prices.push($(this).text());
          i++;
        });
        let maxLength = Math.max.apply(null, prices).toString().length;

        $(`
          .tier-pricing > .currency:not(.-not-formatted),
          .tier-pricing-year > .currency:not(.-not-formatted),
          .tier-pricing > .value:not(.-not-formatted),
          .tier-pricing-year > .value:not(.-not-formatted),
          .tier-pricing-year > .month:not(.-not-formatted),
          .sale-group,
          .tier-pricing > .sale-group > .currency:not(.-not-formatted),
          .tier-pricing > .sale-group > .value:not(.-not-formatted),
          .tier-pricing > .sale-group > .month:not(.-not-formatted)
        `).addClass('-characters-' + maxLength);

      }

    }
  });

}

/**
 * Calls setProductData for every product object
 *
 * @param products - PS products object
 * @return void
 */
function setProductsData(products) {
  for (var product_type in products) {
    for (var product_id in products[product_type]) {
      setProductData(product_id, products[product_type][product_id]);
    }
  }
}

// Formating Price
function formattedPrice(integer_price) {
  var formatted_price = integer_price;
  return formatted_price;
}

/**
 *  Renders pricing page for a product:
 *  - format and renders price
 *
 * @param product_id - PS product ID
 * @param product_data - object with slug, price and name attributes
 * @return void
 */
function setProductData(product_id, product_data) {
  // Detect the SA Pricing page
  const isSAPricingPage = $('#px-layout').hasClass('sa-pricing');

  // Collect data for each item
  var
    name = product_data['name'],
    slug = product_data['slug'],
    currency_code = product_data['currencyCode'],
    period = product_data['period'],
    period_number = product_data['period_number'],
    price = product_data['price'],
    product_family = product_data['product-family'],
    product_name = product_data['product-name'],
    $purchase_button = $('.px-product-purchase-link-' + slug),
    experiment = 'no';

  // Replace product_id if this is experimental plan
  if (product_id !== product_data.priceId) {
    slug = product_data.slug;
    product_id = product_data.priceId;
    experiment = 'yes';
  }

  // Set data to button
  $purchase_button
    .attr('data-action', 'px-checkout')
    .attr('data-price-id', product_id)
    .attr('data-gtm-plan', slug)
    .attr('data-gtm-id', product_id)
    .attr('data-gtm-period', period_number)
    .attr('data-currency-code', currency_code)
    .attr('data-period', period)
    .attr('data-product-family', product_family)
    .attr('data-product-name', product_name)
    .attr('data-price', price)
    .attr('data-gtm-price', price)
    .attr('data-experiment', experiment);

  // Mark container
  $purchase_button.closest('.tier').attr('data-name', name);

  // Output data to HTML
  var
    $main = $('.pricing-container'),
    $container = $('[data-name="' + name + '"]'),
    $currency = $container.find('[data-type="currency"]'),
    $currency_trial = $('[data-type="currency-trial"]'),
    $month = $container.find('[data-type="payment-period"]'),
    $billing = $container.find('[data-type="billing-period"]'),
    $button = $container.find('button'),
    $currency_text = $('[data-type="currency_text"]'),
    $currency_note = $('.smartslides-pricing__currency-note'),
    $flag = $('.smartslides-pricing__currency-note__flag'),
    $pricing_year = $container.find('.tier-pricing-year'),
    $highlight_note = $container.find('.recommended_text'),
    $bundle_highlight_note = $('#bundle-recommended-text');

  var currency = Currencies.symbols.get(currency_code);
  var currency_text = Currencies.names.get(currency_code);

  // Reduce currensies sizes
  if (
    currency_code === 'BRL' ||
    currency_code === 'MYR' ||
    currency_code === 'PKR' ||
    currency_code === 'TJS' ||
    currency_code === 'BYN' ||
    currency_code === 'MDL'
  ) {
    $currency.addClass('-currency-hide');
  }

  if (currency_code === 'RUB') currency = '₽';
  else if (currency_code === 'TRY') currency = '₺';
  else if (currency_code === 'UAH') currency = '₴';
  else if (currency_code === 'BRL') currency = 'R$';
  else if (currency_code === 'MYR') currency = 'RM';
  else if (currency_code === 'ARS') currency = '$';
  else if (currency_code === 'KGS') currency = 'с';
  else if (currency_code === 'PKR') currency = '₨';
  else if (currency_code === 'TJS') currency = 'SM';
  else if (currency_code === 'BYN') currency = 'Rbl';
  else if (currency_code === 'AUD') currency = '$';
  else if (currency_code === 'US$') currency = '$';
  else if (currency_code === 'VND') currency = '₫';
  else if (currency_code === 'IDR') currency = 'Rp';

  // Flag
  if (currency.includes('$') && currency_code !== 'USD' && currency !== 'R$') {
    currency = '$';
    $flag.addClass('fi-' + currency_code.slice(0, 2).toLowerCase());
  }

  // Decrease sizer of the currency symbol (sale)
  if (currency.length > 2 && currency !== 'Rbl') {
    $container.find('.tier-pricing .sale-group .currency.display-annual.-sale').addClass('-currency-downsize');
  }

  if (currency === 'US$' || currency === '$US') {
    currency = '$';
  }

  // Space after the currency name/code
  if (
    (
      currency.length >= 2 &&
      currency.slice(currency.length - 1, currency.length) !== '$'
    ) ||
    currency === '₫' ||
    currency === 'Rp'
  ) {
    currency += '<span class="small-space">&nbsp;</span>';
  }

  // Loaded
  $main.removeClass('-loading').addClass('-loaded');
  $currency.html(currency).addClass('-loaded');
  $month.addClass('-loaded');
  $billing.addClass('-loaded');
  $button.removeAttr('disabled');

  if ($currency_text.text() === '') {
    $currency_text.text(currency_text);
    $currency_note.addClass('-loaded');
  }

  if ($currency_trial.text() === '') {
    $currency_trial.html(currency);
  }

  if (price === 0) {
    $pricing_year.addClass('-hidden');
  }

  if (experiment === 'yes') {
    if (price === 0) {
      $month.hide();
      $pricing_year.addClass('-hidden');
      $button.text('Start Now');
      $highlight_note.text('Free Forever');
      $highlight_note.closest('.tier').addClass('recommended');
      $bundle_highlight_note.text('');
      $bundle_highlight_note.closest('.tier').removeClass('recommended');
    } else if (price > 0) {
      $highlight_note.text('Most Popular');
      $highlight_note.closest('.tier').addClass('recommended');
      $bundle_highlight_note.text('');
      $bundle_highlight_note.closest('.tier').removeClass('recommended');
    }
  }

  const SAPlansCount = Object.keys(loadedGQLProducts.sa).length;

  // const saleFactor = 0.6; // SA 10-Year Sale
  const saleFactor = 1;

  // SA Pricing page without monthly option
  if (isSAPricingPage && SAPlansCount === 2) {
    let $price = $container.find('.tier-pricing').find('[data-type="price"]');
    let $price_year = $container.find('.tier-pricing-year').find('[data-type="price"]');
    let $price_yearly_discount = $container.find('[data-type="price_yearly_discount"] [data-type="price"]');
    let $price_year_yearly_discount = $container.find('[data-type="price-year_yearly_discount"] [data-type="price"]');

    if (name === 'All Apps Bundle Yearly' && period === 'Yearly') {
      $price.html(formattedPrice(Math.floor(price / 12))).addClass('-loaded');
      $price_year.html(formattedPrice(Math.floor(price))).addClass('-loaded');

      $price_yearly_discount.html(formattedPrice(Math.floor(price / 12 * saleFactor ))).addClass('-loaded');
      $price_year_yearly_discount.html(formattedPrice(Math.floor(price * saleFactor))).addClass('-loaded');
    } else if (!name.includes('All Apps Bundle')) {
      $price.html(formattedPrice(Math.floor(price / 12))).addClass('-loaded');
      $price_year.html(formattedPrice(Math.floor(price))).addClass('-loaded');
    }
  }

  // PSS or PSF Pricing page or SA Pricing page with monthly option
  else {
    let $price_monthly = $container.find('[data-type="price_monthly"] [data-type="price"]');
    let $price_yearly = $container.find('[data-type="price_yearly"] [data-type="price"]');
    let $price_yearly_discount = $container.find('[data-type="price_yearly_discount"] [data-type="price"]');

    let $price_year_monthly = $container.find('[data-type="price-year_monthly"] [data-type="price"]');
    let $price_year_yearly = $container.find('[data-type="price-year_yearly"] [data-type="price"]');
    let $price_year_yearly_discount = $container.find('[data-type="price-year_yearly_discount"] [data-type="price"]');

    if (period === 'Monthly') {
      $price_monthly.html(formattedPrice(Math.floor(price))).addClass('-loaded');
      $price_year_monthly.html(formattedPrice(Math.floor(price)) * 12).addClass('-loaded');
    } else if (period === 'Yearly') {
      $price_yearly.html(formattedPrice(Math.floor(price / 12))).addClass('-loaded');
      $price_yearly_discount.html(formattedPrice(Math.floor(price / 12 * saleFactor ))).addClass('-loaded');

      $price_year_yearly.html(formattedPrice(Math.floor(price))).addClass('-loaded');
      $price_year_yearly_discount.html(formattedPrice(Math.floor(price * saleFactor))).addClass('-loaded');
    }
  }
}
