import $ from 'jquery'

export function Sale() {
  // Hero animation
  setTimeout(() => {
    $('.main-page__hero-sale').addClass('-animated');
  }, 150);

  // FAQ
  if ($('.sale__faq__list__item').length > 0) {
    $('.sale__faq__list__item').each(function() {
      let $e = $(this);
      let $question = $e.find('.sale__faq__list__item__question');

      $question.on('click', function() {
        $e.toggleClass('-open');
      });
    });
  }
}
